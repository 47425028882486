import { action } from '@ember/object';
import {
  ASSIGNED_PARTY_PERMISSION,
  ERPS,
  PARTY_PARTY_PERMISSIONS,
  PRODUCT_FRUITS_VALUES,
  SEARCH_PAGE_SIZES,
} from '@mvb/tix-ui/constants';
import ENV from '@mvb/tix-ui/config/environment';
// eslint-disable-next-line import/named
import { cached } from '@glimmer/tracking';
import { didCancel, task, timeout } from 'ember-concurrency';
import { isPresent } from '@ember/utils';
import { runInDebug } from '@ember/debug';
import { waitFor } from '@ember/test-waiters';
import Evented from '@ember/object/evented';
import Service, { service } from '@ember/service';
import sortBy from 'lodash-es/sortBy';
import window from 'ember-window-mock';

export default class UserService extends Service.extend(Evented) {
  @service api;
  @service abilities;
  @service errors;
  @service features;
  @service intl;
  @service location;
  @service modals;
  @service progress;
  @service router;
  @service session;
  @service store;
  @service productFruits;

  constructor() {
    super(...arguments);
    window.addEventListener('storage', this.onStorageChange);
  }

  willDestroy() {
    window.removeEventListener('storage', this.onStorageChange);
  }

  get assignedParties() {
    return this.current?.assignedParties ?? [];
  }

  get assignedPartiesMvbIds() {
    return this.assignedParties.map((assignedParty) => assignedParty?.party?.mvbId).filter(Boolean);
  }

  get assignedPartiesPermissionsMap() {
    return this.assignedParties.map((assignedParty) => {
      return {
        id: assignedParty.id,
        mvbId: assignedParty.party?.mvbId,
        permissions: assignedParty.permissionSet?.permissions ?? [],
        permissionSetName: assignedParty.permissionSet?.name,
        premiumStatus: assignedParty.party?.premiumStatus,
      };
    });
  }

  get assignedPartiesSorted() {
    let compareOptions = { numeric: true, sensitivity: 'base' };
    return this.assignedParties.slice().sort((a, b) => {
      let sort = a.party?.name.localeCompare(b.party?.name, compareOptions);

      if (0 === sort) {
        sort = a.party?.mvbId.localeCompare(b.party?.mvbId, compareOptions);
      }

      return sort;
    });
  }

  get canAccessBacklist() {
    return this.abilities.can('backlist.access', this.selectedParty);
  }

  get current() {
    let user = this.userTask.lastSuccessful?.value;
    return user && !user.isDeleted ? user : undefined;
  }

  get currentGroups() {
    return this.updateSelectedPartyGroupsTask.lastSuccessful?.value ?? [];
  }

  get currentUserSettings() {
    return this.userSettingsTask.lastSuccessful?.value ?? {};
  }

  get favoritePublishers() {
    return this.currentUserSettings.favoritePublishers;
  }

  get isAdminOfAnyParty() {
    return this.assignedPartiesPermissionsMap.some(
      (permissionsMap) => permissionsMap?.permissionSetName === ASSIGNED_PARTY_PERMISSION.ADMIN
    );
  }

  get selectedCurrencyCountry() {
    if (isPresent(this.current?.currency) && isPresent(this.current?.currencyCountry)) {
      return `${this.current.currency}_${this.current.currencyCountry}`;
    }
    return ENV.defaultCurrency;
  }

  @cached
  get selectedParty() {
    if (!this.assignedParties || !this.current) {
      return undefined;
    }

    let selectedId = this.current?.userSettings?.selectedParty?.id;

    return this.assignedParties.find((assignedParty) => assignedParty.id === selectedId);
  }

  get selectedPartyHasPremiumStatus() {
    return this.selectedParty?.isPremium;
  }

  get selectedPartyPermissions() {
    return this.selectedParty?.permissionSet?.permissions ?? [];
  }

  get selectedUserPagesize() {
    return this.currentUserSettings.pageSize ?? SEARCH_PAGE_SIZES[0];
  }

  get useBacklist() {
    return this.current?.userSettings?.backlistChecked;
  }

  @task({ drop: true })
  @waitFor
  *loadTask() {
    yield this.userTask.perform();
    if (this.features.isEnabled('product-fruits')) {
      let eprQualifier = yield this.determineWwsName();
      let associatedBookGroups = yield this.determineBookGroups();

      let productFruitsModel = {
        userId: this.current?.id,
        userType: this.current?.type,
        premiumStatus: this.getPremiumStatus(),
        userRole: this.getUserRole(),
        erpQualifier: eprQualifier,
        associatedBookGroups,
        thaliaHug: this.anyPartyIsThaliaOrHugendubel(),
        partyPermissionWebToPrint: this.hasPartyPermissionWebToPrint(),
      };

      this.productFruits.initProductFruits(productFruitsModel);
    }

    yield this.userSettingsTask.perform();
    // needed for product search filter "highlightsBookGroup"
    yield this.updateSelectedPartyAffiliatesTask.perform();

    // needed to get the erpSalesSetup.active flag
    if (this.features.isEnabled('eurosoftWawiSettings')) {
      yield this.selectedPartySettingsTask.perform();
    }

    // needed for groups in notes
    if (this.features.isEnabled('groups')) {
      yield this.updateSelectedPartyGroupsTask.perform();
    }

    if (!this.current || this.selectedParty || !this.assignedParties?.[0]) {
      return;
    }

    yield this.updateSelectedParty(this.assignedParties[0]);
  }

  @task({ keepLatest: true })
  @waitFor
  *updateBacklistCheckedTask(value) {
    yield timeout(100);

    if (!this.current) {
      return;
    }

    try {
      this.current.userSettings.backlistChecked = value;
      yield this.current.userSettings.save();
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task({ restartable: true })
  @waitFor
  *updateSelectedPartyAffiliatesTask() {
    try {
      let partyId = this.selectedParty?.party?.id;

      if (partyId) {
        yield this.store.findRecord('party', partyId, {
          include: 'affiliates',
        });
      }
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task({ restartable: true })
  @waitFor
  *updateSelectedPartyGroupsTask() {
    try {
      let partyId = this.selectedParty?.party?.id;

      if (partyId) {
        let groups = yield this.store.query('group', {
          filter: {
            'party.id': partyId,
            unpaged: true,
          },
        });

        // only return those groups the user is a member of
        let groupsUserIsMemberOf = groups.filter((group) => group.isMember);
        return sortBy(groupsUserIsMemberOf, (group) => group.name.toLowerCase());
      }

      return [];
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task({ keepLatest: true })
  @waitFor
  *updateSelectedPartyTask(value) {
    this.trigger('selectedPartyWillChange');

    let wait = this.progress.add({
      message: this.intl.t('userService.text.waitingForSelectedPartyChange', {
        party: value.party.name,
      }),
    });

    try {
      this.currentUserSettings.selectedParty = value;
      yield this.currentUserSettings.save();
      yield this.updateSelectedPartyAffiliatesTask.perform();

      if (this.features.isEnabled('groups')) {
        yield this.updateSelectedPartyGroupsTask.perform();
      }

      localStorage.setItem('sync-selected-party', value.id);

      this.router.currentRoute.name === 'previews.add' || this.router.currentRoute.name === 'previews.preview.edit'
        ? this.location.goToRoute('previews')
        : this.location.goToCurrentRoute();
    } catch (error) {
      if (error && error.name !== 'TransitionAborted') {
        this.errors.handle(error);
      }

      wait.remove();
    }

    this.trigger('selectedPartyDidChange');
  }

  @task
  @waitFor
  *selectedPartySettingsTask() {
    let selectedParty = this.selectedParty?.party;

    if (!selectedParty) {
      return false;
    }

    try {
      let partySetting = yield this.store.findRecord('party-setting', selectedParty?.id, {
        include: 'erpSalesSetup.active,erpSetup.erp',
      });
      return (
        (partySetting?.erpSalesSetup?.active ?? false) &&
        (partySetting?.erpSetup?.erp?.name === ERPS.EUROSALESFLOW ?? false)
      );
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task
  @waitFor
  *userSettingsTask(forceReload) {
    if (!this.current) {
      return;
    }

    if (forceReload) {
      try {
        yield this.current.userSettings.reload();

        return this.current.userSettings;
      } catch (error) {
        this.errors.handle(error);
      }
    }

    return this.current.userSettings;
  }

  @task({ restartable: true })
  @waitFor
  *userTask() {
    try {
      let userId = this.session.data?.authenticated?.userId;

      if (!userId) {
        runInDebug(() => {
          // eslint-disable-next-line no-console
          console.error('Invalid session data in session store');
        });
        this.session.invalidate();
        return;
      }

      return yield this.store.findRecord('user', userId, {
        include: 'assignedParties.party,assignedParties.permissionSet,userSettings',
      });
    } catch {
      this.session.invalidate();
    }
  }

  @action
  onStorageChange(event) {
    if (event.key === 'sync-selected-party' && event.newValue !== this.selectedParty?.id) {
      //synchronize selected party across tabs e.g.
      window.location.reload();
    }
  }

  @action
  updateSelectedParty(value) {
    return this.updateSelectedPartyTask.perform(value);
  }

  anyPartyHasAnyPermission(permissions) {
    return permissions.some((permission) =>
      this.assignedParties?.some((assignedParty) => assignedParty.hasPermission(permission))
    );
  }

  anyPartyIsPremium() {
    return this.assignedParties?.some((assignedParty) => assignedParty.isPremium);
  }

  anyPartyIsThaliaOrHugendubel() {
    if (!this.hasAssignedParties()) {
      return null;
    }
    let isAnyPartyThalia = this.assignedParties.some((assignedParty) => assignedParty.party.thaliaBookstore);
    if (isAnyPartyThalia) {
      return PRODUCT_FRUITS_VALUES.THALIA;
    }
    let isAnyPartyHugendubel = this.assignedParties.some((assignedParty) => assignedParty.party.hugendubelBookstore);
    if (isAnyPartyHugendubel) {
      return PRODUCT_FRUITS_VALUES.HUGENDUBEL;
    }

    return null;
  }

  anyPremiumPartyHasAnyPermission(permissions) {
    return permissions.some((permission) =>
      this.assignedParties?.some((assignedParty) => assignedParty.isPremium && assignedParty.hasPermission(permission))
    );
  }

  async determineBookGroups() {
    if (!this.hasAssignedParties()) {
      return null;
    }
    let bookGroups = [];
    let parties = await this.store.query('party', {
      filter: {
        allAssignedParties: true,
      },
      include: 'affiliates',
    });
    for (let party of parties) {
      if (party.affiliates) {
        for (let affiliate of party.affiliates) {
          if (affiliate.isBookGroup) {
            bookGroups.push(affiliate.name);
          }
        }
      }
    }
    return bookGroups;
  }

  async determineWwsName() {
    if (!this.hasAssignedParties()) {
      return null;
    }
    let result = await this.store.query('party-setting', {
      filter: {
        allAssignedParties: true,
      },
      include: 'erpSetup,erpSetup.erp',
    });
    let qualifier = null;
    for (let partySetting of result) {
      if (partySetting.erpSetup) {
        qualifier = partySetting.erpSetup?.erp?.name;
        break;
      }
    }
    return qualifier;
  }

  isErpSalesEnabled() {
    return this.selectedPartySettingsTask?.lastSuccessful?.value ?? false;
  }

  getPremiumStatus() {
    if (!this.hasAssignedParties()) {
      return null;
    }
    return this.anyPartyIsPremium() ? PRODUCT_FRUITS_VALUES.PREMIUM : PRODUCT_FRUITS_VALUES.BASIC;
  }

  getUserRole() {
    if (!this.hasAssignedParties()) {
      return null;
    }
    if (this.hasPermissionSetInAnyParty(PRODUCT_FRUITS_VALUES.ADMIN.toLowerCase())) {
      return PRODUCT_FRUITS_VALUES.ADMIN;
    } else if (this.hasPermissionSetInAnyParty(PRODUCT_FRUITS_VALUES.ORDER_MEMBER)) {
      return PRODUCT_FRUITS_VALUES.ORDER_MEMBER;
    } else {
      return PRODUCT_FRUITS_VALUES.MEMBER;
    }
  }

  hasPartyPermissionWebToPrint() {
    return this.assignedParties?.some((assignedParty) =>
      assignedParty?.party?.partyPermissions?.includes(PARTY_PARTY_PERMISSIONS.WEB_TO_PRINT)
    );
  }

  hasAssignedParties() {
    return this.assignedParties?.length > 0;
  }

  hasAnyPermission(permissions) {
    return permissions.some((permission) => this.selectedPartyPermissions.includes(permission));
  }

  hasPermission(permission) {
    return this.hasAnyPermission([permission]);
  }

  hasPermissionSetInAnyParty(name) {
    return this.assignedParties?.some((assignedParty) => assignedParty.permissionSet?.name === name);
  }

  async load() {
    if (!this.session.isAuthenticated) {
      return;
    }

    try {
      await this.loadTask.perform();
    } catch (error) {
      if (!didCancel(error)) {
        throw error;
      }
    }
  }

  reloadUserSettings() {
    return this.userSettingsTask.perform(true);
  }

  reloadPartySettings() {
    return this.selectedPartySettingsTask.perform();
  }
}
