import { attr, belongsTo, hasMany } from '@ember-data/model';
import { PARTY_TYPE, PREMIUM_STATUS } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Model from './-base';

export default class PartyModel extends Model {
  @service intl;
  @service features;
  @service user;

  @attr({
    defaultValue() {
      return [];
    },
  })
  partyPermissions;
  @attr('boolean') active;
  @attr('string') cogFileName;
  @attr('string') prFileName;
  @attr('date') created;
  @attr('boolean') exportCollectionOfGoods;
  @attr('string') mvbId;
  @attr('string') name;
  @attr('string') premiumStatus;
  @attr('boolean') premiumUsed; // true if the premium test phase has been used
  @attr('boolean') hugendubelBookstore;
  @attr('boolean') thaliaBookstore;
  @attr('date') premiumExpiration;
  @attr() premiumHistory;
  @attr('string') type;
  @attr('string') fax;
  @attr('string') email;
  @attr('string') telephone;
  @attr('string') url;
  @attr('string') deliveryHintsDE;
  @attr('string') deliveryHintsAt;
  @attr('string') deliveryHintsCh;
  @attr('string') deliveryAddendum;

  @belongsTo('address', { async: false, inverse: null }) address;
  @belongsTo('party', { async: true, inverse: 'imprints' }) parent;
  @belongsTo('party-info', { async: true, inverse: 'party' }) partyInfo;
  @belongsTo('party-setting', { async: true, inverse: 'party' }) partySetting;

  @hasMany('customer-contact', { async: false, inverse: 'party' }) customerContacts;
  @hasMany('party', { async: false, inverse: 'affiliations' }) affiliates;
  @hasMany('party', { async: false, inverse: 'affiliates' }) affiliations;
  @hasMany('party', { async: false, inverse: 'parent' }) imprints;
  @hasMany('permission-set', { async: true, inverse: 'party' }) permissionSets;

  get isBookGroup() {
    return this.type === PARTY_TYPE.BOOK_GROUP;
  }

  get isBookstore() {
    return this.type === PARTY_TYPE.BOOKSTORE;
  }

  get isMemberOfAnyBookGroup() {
    return this.affiliates?.some((party) => party.isBookGroup);
  }

  get isPremium() {
    return this.premiumStatus === PREMIUM_STATUS.PREMIUM;
  }

  get isPublisher() {
    return this.type === PARTY_TYPE.PUBLISHER;
  }

  get isSalesCoop() {
    return this.type === PARTY_TYPE.SALES_COOP;
  }

  get nameWithMvbId() {
    return this.intl.t('modelParty.text.nameWithMvbId', { name: this.name, mvbId: this.mvbId });
  }

  get translatedPremiumStatus() {
    let translatedPremiumStatus = this.intl.t(`modelParty.text.premiumStatus.${this.premiumStatus ?? 'unknown'}`);

    if (!this.features.isEnabled('bookstore-premium') && this.isPremium && this.user.current.isBookstore) {
      translatedPremiumStatus = this.intl.t(`modelParty.text.premiumStatus.${PREMIUM_STATUS.BASIC}`);
    }

    return translatedPremiumStatus;
  }
}
