import { productFruits } from 'product-fruits';
import ENV from '@mvb/tix-ui/config/environment';
import Service from '@ember/service';

export default class ProductFruitsService extends Service {
  initProductFruits(productFruitsModel) {
    productFruits.init(
      ENV.APP.PRODUCT_FRUITS_KEY,
      'de',
      {
        username: productFruitsModel.userId,
        role: productFruitsModel.userType,
        props: {
          premium_status: productFruitsModel.premiumStatus,
          user_role: productFruitsModel.userRole,
          erp_setup: productFruitsModel.erpQualifier,
          book_groups: productFruitsModel.associatedBookGroups,
          thalia_hug: productFruitsModel.thaliaHug,
          // We can't rename to party_permissions, because we would otherwise break the product fruits data
          additional_right_web_print: productFruitsModel.partyPermissionWebToPrint,
        },
      },
      { disableLocationChangeDetection: false }
    );
  }
}
