import { assert, runInDebug } from '@ember/debug';
import { DateTime } from 'luxon';
import { didCancel, task, timeout } from 'ember-concurrency';
import {
  FILTER_DATE_FORMAT,
  FILTER_MAX_DATE,
  FILTER_MIN_DATE,
  MAX_ENTITIES_FOR_SEARCH,
  PUBLICATION_DATE,
  ROUTES_WITH_PACKED_QUERY_PARAMS,
  SEARCH_PAGE_SIZES,
} from '@mvb/tix-ui/constants';
import { isPresent } from '@ember/utils';
import { reject } from 'rsvp';
import { scheduleOnce } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import intersection from 'lodash-es/intersection';
import isEqual from 'lodash-es/isEqual';
import Service, { service } from '@ember/service';

export default class SearchBaseService extends Service {
  @service abilities;
  @service errors;
  @service router;

  // it is assumed _maxEntitiesForSearch is a multiple of pagesize
  _maxEntitiesForSearch = MAX_ENTITIES_FOR_SEARCH;
  _searchMap = {};

  get currentURL() {
    return this.router.currentURL?.split(/[#?]/)[0];
  }

  // if the current url search has been run and has any results
  get hasResults() {
    return this.meta.total > 0 && this.results;
  }

  // if the search task is currently running
  get isRunning() {
    return this.searchTask.isRunning;
  }

  // if the search task is running for the first time
  get isRunningInitially() {
    return !this.searchTask.performCount || (this.searchTask.performCount === 1 && this.searchTask.isRunning);
  }

  // these are the meta information for the current url
  get meta() {
    return this.searchMap.meta;
  }

  set meta(meta) {
    if (this.currentURL) {
      let newMeta = this.meta;

      if (undefined !== meta.facets) {
        newMeta.facets = meta.facets;
      }

      if (undefined !== meta.filters) {
        newMeta.filters = meta.filters;
      }

      if (undefined !== meta.page) {
        let number = Number(meta.page) - 1;
        newMeta.number = number >= 0 ? number : 0;
      }

      if (undefined !== meta.size) {
        newMeta.size = Number(meta.size);
      }

      if (undefined !== meta.sort) {
        newMeta.sort = meta.sort;
      }

      if (undefined !== meta.total) {
        newMeta.total = meta.total;
        newMeta.totalPagination = meta.total > this._maxEntitiesForSearch ? this._maxEntitiesForSearch : meta.total;
      }

      this.searchMap.meta = { ...this.searchMap.meta, ...newMeta };
    }
  }

  get metaDefaults() {
    return {
      number: 0,
      size: 0,
      sortOrders: this.sortOrders,
      total: 0,
    };
  }

  // this is the saved model (from the searchTask options) for the current url
  get model() {
    return this.searchMap.model;
  }

  set model(model) {
    if (this.currentURL) {
      this.searchMap.model = model;
    }
  }

  // this is the api search call query for the current url
  get query() {
    return this.searchMap.query;
  }

  set query(query) {
    if (this.currentURL) {
      this.searchMap.query = query;
    }
  }

  // these are the saved unpacked query parameters for the current url
  get queryParams() {
    return this.searchMap.queryParams;
  }

  set queryParams(queryParams) {
    if (this.currentURL) {
      for (let queryParameter in { ...this.queryParamsDefaults, ...this.queryParamsUserSpecific }) {
        if (undefined !== queryParams?.[queryParameter]) {
          this.searchMap.queryParams[queryParameter] = queryParams[queryParameter];
        }
      }
    }
  }

  // these are the ids of all entities returned from the search call for the current url
  get resultIds() {
    return this.searchMap.resultIds;
  }

  set resultIds(resultIds) {
    if (this.currentURL) {
      this.searchMap.resultIds = resultIds;
    }
  }

  get results() {
    return this.searchTask.lastSuccessful?.value;
  }

  // this is the searchmap of the current url
  get searchMap() {
    if (this.currentURL) {
      this._initSearchMap();
      return this._searchMap[this.currentURL];
    }

    return {};
  }

  get searchQuery() {
    let query = this.createSearchQuery({ model: this.model, queryParams: this.queryParams });

    // override query for params where BE differs from URL usage
    if (query.filter?.announcementDate) {
      let dateFrom = DateTime.now().plus({ days: 1 }).startOf('day').toFormat(FILTER_DATE_FORMAT);
      let dateTo = FILTER_MAX_DATE;

      query.filter.announcementDate = `${dateFrom},${dateTo}`;
    }

    if (query.filter?.customerContact) {
      delete query.filter.customerContact;
    }

    if ([PUBLICATION_DATE.UNPUBLISHED, PUBLICATION_DATE.PUBLISHED].includes(query.filter?.publicationDate)) {
      let dateFrom = DateTime.now().plus({ days: 1 }).startOf('day').toFormat(FILTER_DATE_FORMAT);
      let dateTo = FILTER_MAX_DATE;

      if (PUBLICATION_DATE.PUBLISHED === query.filter.publicationDate) {
        dateFrom = FILTER_MIN_DATE;
        dateTo = DateTime.now().startOf('day').toFormat(FILTER_DATE_FORMAT);
      }

      query.filter.publicationDate = `${dateFrom},${dateTo}`;
    }

    return query;
  }

  @task({ restartable: true })
  @waitFor
  *searchTask(queryParamsOverride, options, queryParamsFromRoute) {
    this.updateAdditionalInformationFiltersTask.cancelAll();

    let page = 1;
    let results = [];
    queryParamsOverride = queryParamsOverride || {};

    try {
      // delay search
      let delay = options?.delay ?? 0;

      if (this.searchTask.performCount > 1 && delay > 0) {
        yield timeout(delay);
      }

      // set model
      if (undefined !== options?.model) {
        this.model = options.model;
      }

      // set filter query params to defaults
      if (options?.useFilterDefaults) {
        let queryParamsDefaultsFilters = {};

        for (let queryParamFilter of this.queryParamsFilters) {
          let value = this.queryParamsDefaults[queryParamFilter];

          if (undefined !== this.queryParamsUserSpecific[queryParamFilter]) {
            value = this.queryParamsUserSpecific[queryParamFilter];
          }

          queryParamsDefaultsFilters[queryParamFilter] = value;
        }

        // also set additional information filters to defaults
        for (let queryParam in this.queryParamsDefaultsForAdditionalInformation) {
          queryParamsDefaultsFilters[queryParam] = this.queryParamsDefaultsForAdditionalInformation[queryParam];
        }

        // also set notes filters to defaults
        for (let queryParam in this.queryParamsDefaultsForNotes) {
          queryParamsDefaultsFilters[queryParam] = this.queryParamsDefaultsForNotes[queryParam];
        }

        queryParamsOverride = { ...queryParamsOverride, ...queryParamsDefaultsFilters };
      }

      // set param overrides for unallowed query params
      queryParamsOverride = this.overrideQueryParameters({ ...queryParamsOverride });

      // get actual query params
      let { queryParams, queryParamsTransition } = this._evaluateQueryParams({
        queryParamsCurrent: this.queryParams,
        queryParamsDefaults: this.queryParamsDefaults,
        queryParamsFromRoute,
        queryParamsOverride,
        queryParamsUserSpecific: this.queryParamsUserSpecific,
      });

      // replace url (and restart task) if query params have changed
      if (Object.entries(queryParamsTransition).length > 0) {
        if (options?.replaceRoute === true) {
          this.searchTask._performCount--;
          this.router.replaceWith({ queryParams: queryParamsTransition });
        } else {
          this.router.transitionTo({ queryParams: queryParamsTransition });
        }
        return reject();
      }

      // restrict size
      if (undefined !== queryParams.size) {
        let size = Number(queryParams.size);
        let sizeDefault = this.queryParamsUserSpecific.size ?? this.queryParamsDefaults.size ?? SEARCH_PAGE_SIZES[0];

        if (Number.isNaN(size) || (!SEARCH_PAGE_SIZES.includes(size) && size !== sizeDefault)) {
          this.searchTask._performCount--;
          this.router.replaceWith({ queryParams: { size: sizeDefault } });
          return reject();
        }
      }

      // restrict page (has to be done after size)
      if (undefined !== queryParams.page) {
        page = Number(queryParams.page);

        if (Number.isNaN(page) || page < 1) {
          this.searchTask._performCount--;
          this.router.replaceWith({ queryParams: { page: 1 } });
          return reject();
        }

        if (undefined !== queryParams.size && page * queryParams.size > this._maxEntitiesForSearch) {
          this.searchTask._performCount--;
          this.router.replaceWith({ queryParams: { page: Math.floor(this._maxEntitiesForSearch / queryParams.size) } });
          return reject();
        }
      }

      // restrict sort
      if (undefined !== queryParams.sort) {
        let sort = queryParams.sort;
        let sortDefault = this.queryParamsUserSpecific.sort ?? this.queryParamsDefaults.sort ?? '';

        if (!this.sortOrders.some((sortOrder) => sortOrder.value === queryParams.sort) && sort !== sortDefault) {
          this.searchTask._performCount--;
          this.router.replaceWith({ queryParams: { sort: sortDefault } });
          return reject();
        }
      }

      // store current query parameters
      this.queryParams = queryParams;

      // create search query
      let query = this.searchQuery;

      // update meta information
      this.meta = { ...this.queryParams };

      // save query
      this.query = query;

      // execute search call
      results = options?.results ?? (yield this.executeSearchQuery(query));

      // update facets and filters in meta information
      this.meta = { facets: results.meta?.facets ?? [], filters: results.meta?.filters ?? [] };

      // check if any unallowed facet dependent query parameters were used
      let overridenQueryParamsOfFacets = this.overrideQueryParametersOfFacetsAndAdditionalInformationFilters({
        ...this.queryParams,
      });

      for (let queryParam in overridenQueryParamsOfFacets) {
        if (undefined === this.queryParams[queryParam]) {
          delete overridenQueryParamsOfFacets[queryParam];
        }
      }

      if (!isEqual(queryParams, overridenQueryParamsOfFacets)) {
        this.searchTask._performCount--;
        this.router.replaceWith({ queryParams: overridenQueryParamsOfFacets });
        return reject();
      }

      // update total in meta information
      this.meta = { total: results.meta?.total ?? 0 };

      // save ids of results
      this.resultIds = results.map((result) => result.id);

      // go to last page with results if no results are found
      if (page !== 1 && !results?.length) {
        this.searchTask._performCount--;
        this.router.replaceWith({ queryParams: { page: results.meta?.number || 1 } });
        return reject();
      }

      // fetch additional data and map results
      results = yield this.mapResults({ model: this.model, queryParams: this.queryParams, results });
    } catch (error) {
      this.errors.handle(error);

      if (page !== 1) {
        this.searchTask._performCount--;
        this.router.replaceWith({ queryParams: { page: 1 } });
        return reject();
      }
    }

    return results;
  }

  @task({ restartable: true })
  @waitFor
  *updateAdditionalInformationFiltersTask() {
    try {
      let results = yield this.executeSearchQuery(this.query);

      // update only additional information filters after saving them in a preview
      this.meta = { filters: results.meta?.filters ?? [] };

      // check if all set filters are still allowed
      let overridenQueryParamsOfFilters = this._removeUnallowedAdditionalInformationFilters({ ...this.queryParams });

      for (let queryParam in overridenQueryParamsOfFilters) {
        if (undefined === this.queryParams[queryParam]) {
          delete overridenQueryParamsOfFilters[queryParam];
        }
      }

      if (!isEqual(this.queryParams, overridenQueryParamsOfFilters)) {
        this.updateAdditionalInformationFiltersTask._performCount--;
        this.router.replaceWith({ queryParams: overridenQueryParamsOfFilters });
        return;
      }

      // check if total size has changed
      if ((results.meta?.total ?? 0) !== this.meta.total) {
        this.searchTask.perform(null, { results });
      }
    } catch (error) {
      this.errors.handle(error);
    }
  }

  //
  // internal functions
  //

  _evaluateQueryParams({
    queryParamsCurrent,
    queryParamsDefaults,
    queryParamsFromRoute,
    queryParamsOverride,
    queryParamsUserSpecific,
  }) {
    let queryParams = queryParamsFromRoute ? { ...queryParamsDefaults } : { ...queryParamsCurrent };
    let queryParamsTransition = {};

    for (let queryParam in queryParamsCurrent) {
      // add query params from route
      if (undefined !== queryParamsFromRoute?.[queryParam]) {
        queryParams[queryParam] = queryParamsFromRoute[queryParam];
        queryParamsTransition[queryParam] = queryParamsFromRoute[queryParam];
      }

      // add query param overrides
      if (undefined !== queryParamsOverride?.[queryParam]) {
        queryParamsTransition[queryParam] = queryParamsOverride[queryParam];
      }

      // add user specific query params
      if (undefined !== queryParamsUserSpecific?.[queryParam] && undefined === queryParams[queryParam]) {
        queryParamsTransition[queryParam] = queryParamsUserSpecific[queryParam];
      }

      // remove extranous query parameters
      if (undefined === queryParamsTransition[queryParam]) {
        delete queryParamsTransition[queryParam];
      }
    }

    //  return transition params if route params contain a default query param
    for (let qp in queryParamsDefaults) {
      if (queryParamsFromRoute?.[qp] === queryParamsDefaults[qp]) {
        return { queryParams, queryParamsTransition };
      }
    }

    // return transition params if they differ from ascertained query params
    for (let qp in queryParamsTransition) {
      if (queryParams[qp] !== queryParamsTransition[qp]) {
        return { queryParams, queryParamsTransition };
      }
    }

    return { queryParams, queryParamsTransition: {} };
  }

  _initSearchMap() {
    if (this.currentURL && !this._searchMap[this.currentURL]) {
      let { currentURL, metaDefaults, queryParamsDefaults, queryParamsUserSpecific, _searchMap } = this;

      _searchMap[currentURL] = new (class {
        @tracked meta = { ...metaDefaults };
        @tracked model = null;
        @tracked query = {};
        @tracked queryParams = { ...queryParamsDefaults, ...queryParamsUserSpecific };
        @tracked resultIds = [];
      })();
    }
  }

  // reset additional information filters which are not contained in meta to their original value
  _removeUnallowedAdditionalInformationFilters(queryParams) {
    let additionalInformationFilters = {};

    for (let filter of this.meta.filters || []) {
      additionalInformationFilters[filter.name] = filter.filterItems.map((filterItem) => filterItem.value);
    }

    for (let queryParam in this.queryParamsDefaultsForAdditionalInformation) {
      if (!additionalInformationFilters[queryParam]) {
        queryParams[queryParam] = this.queryParamsDefaultsForAdditionalInformation[queryParam];
        continue;
      }

      if (queryParams[queryParam]) {
        queryParams[queryParam] = intersection(
          queryParams[queryParam].split(','),
          additionalInformationFilters[queryParam]
        ).join(',');
      }
    }

    return queryParams;
  }

  //
  // helper functions
  //

  addFiltersOfAdditionalInformationToSearchQuery({ query, queryParams }) {
    if (undefined === query.filter) {
      query.filter = {};
    }

    for (let filter in this.queryParamsDefaultsForAdditionalInformation) {
      if (
        isPresent(queryParams[filter]) &&
        queryParams[filter] !== this.queryParamsDefaultsForAdditionalInformation[filter]
      ) {
        query.filter[filter] = queryParams[filter];
      }
    }
  }

  addFiltersOfNotesToSearchQuery({ query, queryParams }) {
    if (undefined === query.filter) {
      query.filter = {};
    }

    for (let filter in this.queryParamsDefaultsForNotes) {
      if (isPresent(queryParams[filter]) && queryParams[filter] !== this.queryParamsDefaultsForNotes[filter]) {
        query.filter[filter] = queryParams[filter];
      }
    }
  }

  addFiltersToSearchQuery({ query, queryParams }) {
    if (undefined === query.filter) {
      query.filter = {};
    }

    for (let filter of this.queryParamsFilters) {
      if (isPresent(queryParams[filter]) && queryParams[filter] !== this.queryParamsDefaults[filter]) {
        query.filter[`${filter.replaceAll('-', '.')}`] = queryParams[filter];
      }
    }
  }

  getSearchMapForUrl(url) {
    return this._searchMap[url];
  }

  reset() {
    this.searchTask.cancelAll({ resetState: true });
    this.updateAdditionalInformationFiltersTask.cancelAll({ resetState: true });

    // https://github.com/machty/ember-concurrency/issues/498
    this.searchTask._performCount = 0;
    this.updateAdditionalInformationFiltersTask._performCount = 0;
  }

  search() {
    // since we access the current url, we need to wait until initial rendering is finished
    scheduleOnce('afterRender', this, this._search, ...arguments);
  }

  updateAdditionalInformationFilters() {
    if (!this.searchTask.isRunning) {
      return this.updateAdditionalInformationFiltersTask.perform().catch((error) => {
        if (!didCancel(error)) {
          throw error;
        }
      });
    }
  }

  _search() {
    runInDebug(() => {
      let { currentRouteName } = this.router;

      assert(
        `'${currentRouteName}' must be added to ROUTES_WITH_PACKED_QUERY_PARAMS to call search inside searchservice`,
        ROUTES_WITH_PACKED_QUERY_PARAMS.includes(currentRouteName)
      );
    });

    return this.searchTask.perform(...arguments).catch((error) => {
      if (!didCancel(error)) {
        throw error;
      }
    });
  }

  //
  // useful default configurations
  //

  get queryParamsDefaultsForAdditionalInformation() {
    return {
      'category-of-goods-hug': '',
      'category-of-goods': '',
      'has-files-hug': '',
      'has-files': '',
      'has-marketing-hug': '',
      'has-marketing-publisher': '',
      'has-webshop-link': '',
      'listing-recommendation-hugendubel': '',
      'listing-recommendation': '',
      'marketing-recommendation-merged': '',
      'tags-hug': '',
      'has-dispolist-notes-hug': '',
      'has-marketing-events-hug': '',
      'has-comments-hug': '',
      'has-regions-hug': '',
      'seasons-hug': '',
      'ages-hug': '',
      tags: '',
    };
  }

  get queryParamsDefaultsForNotes() {
    return {
      withNoteText: '',
      notesFromCreator: '',
      showPrivateNotes: '',
      showGroupNotes: '',
    };
  }

  get queryParamsDefaultsForPreviews() {
    return {
      advancedSearchCode: null,
      customerContact: '',
      deletableByUser: null,
      editStatus: null,
      page: 1,
      productIsbn: null,
      q: '',
      season: '',
      seasonYear: '',
      sender: '',
      sort: '',
      status: '',
      type: null,
      userId: null,
      sentPreviews: null,
    };
  }

  get queryParamsDefaultsForProducts() {
    return {
      announcementDate: null,
      bookstoreBranchId: '',
      collectionsFilter: null,
      containedItems: null,
      contributorGnd: '',
      contributorIdentifierName: '',
      contributorIsni: '',
      contributorOrcId: '',
      contributorsFilter: '',
      genrecode: '',
      highlights: '',
      highlightsBookGroup: '',
      keywords: '',
      listingThalia: null,
      page: 1,
      partyGenre: '',
      productFormWithDetail: '',
      publicationDate: null,
      publisher: null,
      publisherId: '',
      priceLimitLower: null,
      priceLimitUpper: null,
      q: '',
      readingRationaleMainSubject: null,
      readingRationaleSubject: null,
      retailSale: null,
      searchIsbnGtin: '',
      sort: '',
      themaMainSubject: null,
      themaSubject: null,
      themaQualifier: null,
      title: '',
      withNotes: null,
      withReadCopy: null,
    };
  }

  get queryParamsFiltersForPreviews() {
    return [
      'customerContact',
      'deletableByUser',
      'editStatus',
      'productIsbn',
      'season',
      'seasonYear',
      'sender',
      'status',
      'type',
      'userId',
      'sentPreviews',
    ];
  }

  get queryParamsFiltersForProducts() {
    return [
      'announcementDate',
      'bookstoreBranchId',
      'collectionsFilter',
      'containedItems',
      'contributorGnd',
      'contributorIdentifierName',
      'contributorIsni',
      'contributorOrcId',
      'contributorsFilter',
      'genrecode',
      'highlights',
      'highlightsBookGroup',
      'keywords',
      'listingThalia',
      'partyGenre',
      'priceLimitLower',
      'priceLimitUpper',
      'productFormWithDetail',
      'publicationDate',
      'publisher',
      'publisherId',
      'readingRationaleMainSubject',
      'readingRationaleSubject',
      'retailSale',
      'searchIsbnGtin',
      'themaMainSubject',
      'themaSubject',
      'themaQualifier',
      'title',
      'withNotes',
      'withReadCopy',
    ];
  }

  overrideQueryParametersForPreviews(queryParamsOverride) {
    if (this.abilities.cannot('search.filterPreviewsByCreatedByUser')) {
      queryParamsOverride.userId = this.queryParamsDefaults.userId;
    }

    if (this.abilities.cannot('search.filterPreviewsByEditStatus')) {
      queryParamsOverride.editStatus = this.queryParamsDefaults.editStatus;
    }

    if (this.abilities.cannot('search.filterPreviewsByStatus')) {
      queryParamsOverride.status = this.queryParamsDefaults.status;
    }

    return queryParamsOverride;
  }

  overrideQueryParametersForProducts(queryParamsOverride) {
    if (this.abilities.cannot('search.filterProductsByAnnouncementDate')) {
      queryParamsOverride.announcementDate = this.queryParamsDefaults.announcementDate;
    }

    if (this.abilities.cannot('search.filterProductsByBookstoreBranchId')) {
      queryParamsOverride.bookstoreBranchId = this.queryParamsDefaults.bookstoreBranchId;
    }

    if (this.abilities.cannot('search.filterProductsByHighlightsBookGroup')) {
      queryParamsOverride.highlightsBookGroup = this.queryParamsDefaults.highlightsBookGroup;
    }

    if (this.abilities.cannot('search.filterProductsByWithNotes')) {
      queryParamsOverride.withNotes = this.queryParamsDefaults.withNotes;
    }

    if (this.abilities.cannot('search.filterProductsByWithReadCopy')) {
      queryParamsOverride.withReadCopy = this.queryParamsDefaults.withReadCopy;
    }

    return queryParamsOverride;
  }

  overrideQueryParametersOfFacetsAndAdditionalInformationFiltersForPreviews(queryParamsOverride) {
    return this._removeUnallowedAdditionalInformationFilters(queryParamsOverride);
  }

  overrideQueryParametersOfFacetsAndAdditionalInformationFiltersForProducts(queryParamsOverride) {
    if (this.abilities.cannot('search.filterProductsByGenrecode', null, { searchService: this })) {
      queryParamsOverride.genrecode = this.queryParamsDefaults.genrecode;
    }

    if (this.abilities.cannot('search.filterProductsByListingThalia', null, { searchService: this })) {
      queryParamsOverride.listingThalia = this.queryParamsDefaults.listingThalia;
    }

    if (this.abilities.cannot('search.filterProductsByPartyGenre', null, { searchService: this })) {
      queryParamsOverride.partyGenre = this.queryParamsDefaults.partyGenre;
    }

    return this._removeUnallowedAdditionalInformationFilters(queryParamsOverride);
  }

  //
  // abstract functions
  //

  // overwrite with creation of query object for api call
  createSearchQuery(/* { model, queryParams } */) {
    return {};
  }

  // overwrite with execution of api call
  async executeSearchQuery(/* query */) {
    return [];
  }

  // overwrite with execution of additional api calls and mapping function
  async mapResults({ /* model, queryParams, */ results }) {
    return await results;
  }

  // overwrite with manual query parameter overrides
  // i.e. remove query parameters which are forbidden for current user
  overrideQueryParameters(queryParamsOverride) {
    return queryParamsOverride;
  }

  // reset query parameters for filters dependent on facets and additional information filters
  // i.e. remove query parameters which are not allowed according to the current meta information
  overrideQueryParametersOfFacetsAndAdditionalInformationFilters(queryParamsOverride) {
    return queryParamsOverride;
  }

  // overwrite with non user specific query parameter defaults
  get queryParamsDefaults() {
    return {};
  }

  // overwrite with an array of query parameters which are "after search" filters
  // used to determine "reset all" visibility
  get queryParamsFilters() {
    return [];
  }

  // overwrite with user specific query parameter defaults
  get queryParamsUserSpecific() {
    return {};
  }

  // overwrite with specific sort orders
  get sortOrders() {
    return [];
  }
}
