import { action } from '@ember/object';
import { BUTTON_TYPES, ICON_TYPES } from '@mvb/tix-ui/components/ui/function-bar';
import { MAX_PREVIEWS_FOR_MASS_DELETE, PREVIEW_TYPE } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import Controller from '@ember/controller';
import ModalPreviewNewPreview from '@mvb/tix-ui/components/preview/new-preview-modal';

export default class PreviewsIndexController extends Controller {
  @service abilities;
  @service api;
  @service modals;
  @service('previews') previewsService;
  @service previewList;
  @service router;
  @service user;
  @service('search-previews') searchService;
  @service('query-params') queryParamsService;
  @service manageList;
  @service errors;
  @service intl;
  @service notifications;

  queryParams = ['qp'];
  @tracked qp = '';

  BUTTON_TYPES = BUTTON_TYPES;
  ICON_TYPES = ICON_TYPES;

  @action
  onAddToList() {
    this.previewList.addPreviewsToList(
      this.searchService.meta.total,
      this.searchService.searchQuery?.filter,
      this.searchService.searchQuery?.sort
    );
  }

  get isItemsSelectionEmpty() {
    return this.manageList.isSelectionEmpty || this.searchService.meta.total === 0;
  }

  @action
  async deletePreviews() {
    if (this.isItemsSelectionEmpty) {
      return this.modals.notify({
        message: this.intl.t('modalDeletePreviews.notification.noItemsSelected'),
        close: this.intl.t('modalNotify.action.confirm'),
        name: 'no-items-selected',
      });
    }

    let selectedItems = [...this.manageList.selectedItems];
    let count = this.manageList.inverse ? this.searchService.meta.total - selectedItems.length : selectedItems.length;

    if (count > MAX_PREVIEWS_FOR_MASS_DELETE) {
      this.modals.notify({
        message: this.intl.t('previewsIndex.notification.tooManyDeleted', {
          selected: this.intl.formatNumber(count),
        }),
        close: this.intl.t('modalNotify.action.confirm'),
      });
      return;
    }

    let confirmed = await this.modals.confirm({
      title: this.intl.t('modalDeletePreviews.text.title'),
      message: this.intl.t('modalDeletePreviews.text.body', { count }),
      confirm: this.intl.t('modalDeletePreviews.action.confirm'),
      cancel: this.intl.t('modalDeletePreviews.action.cancel'),
      name: 'mass-delete-previews',
    });

    if (!confirmed) {
      return false;
    }
    await this.deleteMassPreviews.perform(selectedItems);
  }

  @task
  @waitFor
  *deleteMassPreviews(selectedItems) {
    let filters = this.searchService.searchQuery.filter || {};
    try {
      yield this.api.deleteJSON('/previews/delete', {
        filters,
        identifiers: selectedItems,
        invertedSelection: this.manageList.inverse ?? false,
      });
      this.notifications.success(this.intl.t('modalDeletePreviews.notification.success'));
      yield this.router.refreshCurrentRoute();
    } catch (error) {
      this.errors.handle(error);
    }
  }

  get deleteIsDisabled() {
    return !this.queryParamsService.currentQueryParamsWithDefaults?.deletableByUser;
  }

  @action
  addPreview() {
    return this.addPreviewTask.perform();
  }

  @action
  onSendPreview() {
    this.previewsService.onSendPreview(this.searchService.meta.total, this.searchService.searchQuery?.filter);
  }

  @task({ drop: true })
  *addPreviewTask() {
    let queryParams;

    queryParams = this.abilities.can('preview.selectedPartyCanCreateBookstorePreview', this.user.selectedParty)
      ? {
          cmcType: null,
          type: PREVIEW_TYPE.BOOKSTORE_PREVIEW,
        }
      : yield this.modals.open(ModalPreviewNewPreview);

    if (queryParams) {
      this.router.transitionTo('previews.add', { queryParams });
    }
  }
}
